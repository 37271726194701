/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { SaveButton, CustomCheckbox, CheckMark, ButtonContainer, InviteContainer, InviteAmigo,
  NameContainer, ImageInput, ImageContentContainer, InputContainer, StartDateContainer, DateJoiner, CustomMenuItem, StyledDateTime,
  TitleContainer, FieldTitle, ImageContainer, AmigosContainer, TopContainer, ScrollableContainer, Layout, PageTitle, FormContainer,
  StyledInput,CustomDropDown, PlacesContainer, DateIconContainer, RemovePhotoButton, LocationContainer, TimezoneContainer, EventContainer } from './styles';
import { FeaturedEventsContainer } from '../CreateChallenge/styles';
import { RadioButtonContainer } from '../CreateRecipe/styles';
import { ActivityDropdown, ActivityTypeContainer } from '../CreateChallengeForm/styles';
import FeaturedEvents from '../FeaturedEvents';
import Waiting from '../Waiting';
import { getOrientation, resetOrientation, checkImage, getPermissionStatus } from '../../utils/methods';
// import { repeatValues, recurringTypes} from '../../../mockData.json';
import { eventsTierPoints, showEmailNotificationInfo } from '../../../mockData';
import {getAllCompanies} from "../../redux/actions/challengeActions";
import {getUserCompanyDetails, allowChallengeEventForSpouseDependent} from "../../redux/actions/userProfileActions";
import { imageErrorMessage, ImageUrl, TimeZone } from '../../utils/constants';
import Editor from "../CKEditor";
import {EditorContainer} from "../EditWellnessJourney/styles";
import RadioButton from "../CustomRadioButton";
import timezones from "../../../timezones.json";
import Autocomplete from "../Autocomplete";
import {withTranslation} from 'react-i18next';
import LazyImage from '../common/LazyImage/LazyImage';

class CreateEvent extends Component {
  constructor() {
    super();
    this.state = {
      imgSrc: '',
      title: '',
      date: moment(),
      endDate: moment(),
      location: '',
      description: '',
      imageName: '',
      time: moment().format('hh:mm a'),
      endTime: moment().format('hh:mm a'),
      showDateTimeInput: '',
      selectedMenu: 'Select an option',
      showPlaces: true,
      amigosToInvite: [],
      openDateTimePicker: '',
      companies: [],
      city_location: [],
      eventPointValue: 10,
      limitUser: '0',
      addLimitCheckbox: false,
      allDayEvent: 0,
      eventPoints: [],
      isSpouse: false,
      isDependent: false,
      isEmployee: false,
      selectedOption: 0,
      eventLink: '',
      emailOption: {
        "launch_event_email": 1,
        "event_reminder_email": 1,
        "event_complete_email": 1
      },
      eventPointKey: 'Tier 1: 10 points',
      time_zone: TimeZone === "Asia/Calcutta" ? "Asia/Kolkata" : TimeZone,
      companiesPermission: false,
      locationPermission: false,
      recurringEvent: this.recurringTypes[0],
      eventLimitValue: 2,
      buttonStatus:false
    };
  }

  recurringTypes= [
    "does not repeat",
    "weekly"
  ]

  repeatValues= [
    "Repeat Every Day",
    "Repeat once",
    "Repeat Whole Week"
  ]

  componentDidMount() {
    const companiesPermission = getPermissionStatus("Show companies in event", this.props.userPermissions);
    const locationPermission = getPermissionStatus("Show locations in event", this.props.userPermissions);
    if(companiesPermission) {
      if(!this.props.allCompanies){
        this.props.fetchAllCompanies();
      }
    } else {
      this.props.fetchCompanyForUser();
      this.props.allowChallengeEventForSpouseDependent();
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
    document.addEventListener('mousedown', this.handleClick);
    if(this.props.role === 'WELLNESS_CHAMPION') {
      let arr = eventsTierPoints;
      arr.splice(3);
      this.setState({eventPoints: arr});
    } else {
      this.setState({eventPoints: eventsTierPoints});
    }
    this.setState({
      companiesPermission: companiesPermission,
      locationPermission: locationPermission
    })
  }

  componentDidUpdate(prevProps) {
    if(!this.state.companiesPermission && this.props.userCompany !== prevProps.userCompany) {
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  changeLimitCheckBox = () => {
    this.setState({
      addLimitCheckbox: !this.state.addLimitCheckbox
    })
  }

  onChangeLocation = (id) => {
    const { city_location } = this.state;
    if(city_location.includes(id)) {
      let index = city_location.findIndex((item) => item === id);
      if(index > -1) {
        city_location.splice(index, 1);
      }
    } else {
      city_location.push(id);
    }
    this.setState({
      city_location: city_location
    })
  }

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { city_location } = this.state;
    if(city_location.length == locationDetails.length) {
      this.setState({
        city_location: []
      })
    }
    else {
      let arr = [];
      for(let i=0; i< locationDetails.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        city_location: arr
      })
    }
  }

  handleClick = (e) => {

    if (typeof(this.datePickerWrapper) === 'undefined' && this.datePickerWrapper === null) {
      return
    }
    if(!(_.isNull(this.datePickerWrapper)) && !(_.isUndefined(this.datePickerWrapper)) && this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)) {
      this.setState({
        openDateTimePicker: ''
      });
    }
  };

  setDatePickerWrapper = (node) =>  {
    this.datePickerWrapper = node;
  };

  onChange = (e) => {
    let file = e.target.files[0];   
    let fileArr = e.target.files;
    if(e.target.files[0]) {
      if(checkImage(fileArr)) {
        if((file?.size/1000000) <= 20){
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document.getElementById('event-upload-file').value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage
                });
              });
            });
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('event-upload-file').value = '';  
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById('event-upload-file').value = '';
      } 
    }
  };

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeTimeZone = (name, value) => {
    if(name === 'recurringEvent'){
      const {emailOption} = this.state;
      if(value !== this.recurringTypes[0]){
        emailOption['launch_event_email'] = 0;
      }
      this.setState({
        [name]: value,
        emailOption: emailOption
      });
    }else{
      this.setState({
        [name]: value,
      });
    }
  };

  removePhoto = () => {
    document.getElementById('event-upload-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };

  changeDateTimeView = (value) => {
    if(this.state.showDateTimeInput === '') {
      this.setState({
        date: moment(moment().add(1, 'days')._d).format('MM/DD/YYYY'),
        endDate: moment(moment().add(1, 'days')._d).format('MM/DD/YYYY'),
        time: '12:00 am',
        endTime: '11:59 pm',
        showDateTimeInput: value,
        allDayEvent: 1
      })
    } else {
      this.setState({
        date: moment(moment()._d).format('MM/DD/YYYY'),
        endDate: moment(moment()._d).format('MM/DD/YYYY'),
        time: moment().format('hh:mm a'),
        endTime: moment().format('hh:mm a'),
        showDateTimeInput: '',
        allDayEvent: 0
      });
    }
  };

  onSelect = (value) => {
    this.setState({
      selectedMenu: value,
    });
  };

  onSelectPoints = (name, value, key) => {
    this.setState({
      [name]: value,
      eventPointKey: key 
    });
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if(showDateTimeInput === 'date') {
      this.setState({
        date: moment(e._d).format('MM/DD/YYYY'),
        time: moment(e._d).format('hh:mm a'),
        endDate: moment(e._d).format('MM/DD/YYYY'),
        endTime: '11:59 pm',
        openDateTimePicker: '',
      });
    } else if(stateName.includes('ate')) {
      this.setState({
        openDateTimePicker: '',
        [stateName]: moment(e._d).format('MM/DD/YYYY'),
      });
    } else {
      this.setState({
        [stateName]: moment(e._d).format('hh:mm a'),
        openDateTimePicker: stateName
      });
    }
  };

  showDatePicker = (value) => {
    if(this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: ''
      });
    } else {
      this.setState({
        openDateTimePicker: value
      });
    }
  };

  onCheckboxChange = (element) => {
    const { companies } = this.state;
    if(companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if(index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies
    })
  };

  onChangeEmail=(key)=>{
    const {emailOption} = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({emailOption: emailOption});
  }

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allCompanies } = this.props;
    if(this.state.companies.length === allCompanies.length) {
      invitedCompanies = [];
    } else {
      allCompanies.map((company) => {
        invitedCompanies.push(company.id);
      });
    }
    this.setState({
      companies: invitedCompanies
    })
  };

  onLocationChange = (e) => {
    const { getPlacesAction } = this.props;
    this.setState({
      location: e.target.value,
      showPlaces: true
    }, () => {
      if(this.state.location.length > 3 && this.state.location.trim() != '') getPlacesAction(this.state.location.trim());
    });
  };

  setLocation = (location) => {
    this.setState({
      location: location,
      showPlaces: false
    });
  };

  renderPlaces = (places) => places.map((place, index) => (
    <div key={index} onClick={() => this.setLocation(place.description)}>
      <span>{this.props.t(place.description)}</span>
    </div>
  ));

  selectSpouseDependent = (stateName) => {
    this.setState((prev) =>({
      [stateName]: !prev[stateName]
    }))
  };

  callBackForButtonstatus = () =>{
    this.setState({
      buttonStatus:false
    })
  }

  saveEvent = (e) => {
    e.preventDefault();
    const { city_location, title, date, description, imgSrc, time, endDate, endTime, location, 
      eventLink, companies, eventPointValue, addLimitCheckbox, limitUser, allDayEvent, selectedOption, time_zone, emailOption, locationPermission, recurringEvent, eventLimitValue} = this.state;
    const { createEvent, companyDetails } = this.props;
    this.setState({
      buttonStatus:true
    })
    let isRecurringEvent = recurringEvent === this.recurringTypes[0] ? 0 : 1;
    const EventDetails = {
      title,
      companies: companies,
      body: description,
      image : imgSrc,
      end_date: moment(isRecurringEvent ? date : endDate).format('YYYY-MM-DD') + ' ' + moment(endTime, "hh:mm a").format("HH:mm:ss"),
      start_date: moment(date).format('YYYY-MM-DD') +  ' ' + moment(time, "hh:mm a").format("HH:mm:ss"),
      event_location: !selectedOption ? location : null,
      event_link: selectedOption ? eventLink : null,
      event_points: eventPointValue,
      all_day_event: allDayEvent,
      time_zone: time_zone,
      ...emailOption,
      is_recurring_event : isRecurringEvent,
      recurring_type: isRecurringEvent ? recurringEvent : null,
      recurring_event_limit: isRecurringEvent ? eventLimitValue : 0
    };

    if(locationPermission) {
      EventDetails.company_city_states = city_location;
      EventDetails.event_for_spouse = this.state.isSpouse ? 1 : 0;
      EventDetails.event_for_dependent = this.state.isDependent ? 1 : 0
      if(this.props.locationDetails && this.props.locationDetails.length > 0) {
        city_location.length > 0 ?  EventDetails.is_for_employee = 1 : EventDetails.is_for_employee = 0
      }
      else {
        if(companyDetails['spouse_or_dependent']) {
          EventDetails.is_for_employee = this.state.isEmployee ?  1 : 0
        }
        else{
          EventDetails.is_for_employee = 1
        }
      }
    }
    if(addLimitCheckbox) {
      EventDetails.user_limit = limitUser;
    }
    createEvent(EventDetails, this.callBackForButtonstatus);
  };
  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, '');
    trimmedString.length  === 0 || trimmedString.length === 7 ||  trimmedString == '<p></p><p></p>' ?  this.setState({description: ""}) : this.setState({description: newContent})
  };
  toggleRadioButton = (e) => {
    this.setState({selectedOption: parseInt(e.target.value,10)}, ()=>{});
  };

  render() {
    const { title, location, description, imageName, imgSrc, showDateTimeInput, selectedMenu, date, endDate, time, endTime,
      showPlaces, openDateTimePicker, companies, eventLink, city_location, eventPointValue,
      addLimitCheckbox, limitUser, eventPoints, isSpouse, isDependent, isEmployee, selectedOption, eventPointKey, time_zone, emailOption, companiesPermission, locationPermission, recurringEvent, eventLimitValue, buttonStatus} = this.state;
    const { places, isLoading, allCompanies, userCompany, locationDetails, history, companyDetails,t} = this.props;
    const MandatoryFields = ['title', 'imgSrc', 'description', 'companies'];
    let emailOptionKeys = Object.keys(emailOption);
    if((companiesPermission && !allCompanies) || (locationPermission && !userCompany) || isLoading) {
      return <Waiting />
    }

    let isDisabled = false;
    MandatoryFields.map((field) => {
      if(!this.state[field] || _.isEmpty(companies) || !this.state.description.trim() || !this.state.title.trim() ) {
        isDisabled = true;
      }
    });

    if(addLimitCheckbox && !limitUser.trim()) {
      isDisabled = true;
    }

    if((!selectedOption && !location.trim()) || (selectedOption  && !eventLink.trim())) {
      isDisabled = true;
    }

    if(locationPermission){
      if(locationDetails && locationDetails.length > 0 && city_location.length < 1 && !(this.state.isSpouse || this.state.isDependent)) {
        isDisabled = true;
      }
      if(locationDetails && locationDetails.length === 0 && companyDetails['spouse_or_dependent']) {
        if(!(this.state.isSpouse || this.state.isDependent || this.state.isEmployee)){
          isDisabled = true;
        }
      }
    }
    const yesterday = Datetime.moment().subtract(23, 'hour');
    const valid = (currentDate) => showDateTimeInput === 'date' ? currentDate.isAfter(Datetime.moment()) : currentDate.isAfter(yesterday);
    const eventWeeklyLimits = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    // const eventMonthlyLimits = [2, 3, 4, 5, 6];
    const eventLimits = eventWeeklyLimits;
    return (
      <Layout>
        <PageTitle>{t("Create Event")}</PageTitle>
        <EventContainer>
          <FormContainer onSubmit={this.saveEvent}>
            <TitleContainer>
              <FieldTitle>{t("Event Title")}:</FieldTitle>
              <StyledInput
                placeholder={t("Type title here...")}
                name="title"
                onChange={this.onChangeInput}
                value={title}
                maxLength="100"
              />
            </TitleContainer>
            <ImageContainer>
              <FieldTitle>{t("Event Picture")}:</FieldTitle>
              <div>
                { imageName &&
                  <div>
                    <img src={imgSrc} />
                    <ImageContentContainer>
                      <div>{imageName}</div>
                      <RemovePhotoButton color="#aaaaaa" type="button" onClick={this.removePhoto}>{t("Remove photo")}</RemovePhotoButton>
                    </ImageContentContainer>
                  </div>
                }
                <ImageInput htmlFor="event-upload-file" showAtBottom={imageName !== ''}>
                  {imageName ? t('browse new') : t('browse')}
                  <input
                    id="event-upload-file"
                    type="file"
                    name="user"
                    accept=".jpeg, .png, .jpg"
                    multiple={false}
                    onChange={(e) => this.onChange(e)}
                    onClick={(e) => e.target.files[0] && this.onChange(e)}
                  />
                </ImageInput>
              </div>
            </ImageContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>{t("Event Point Value")}:</FieldTitle>
                <ActivityDropdown
                  title={eventPointKey}
                  id="dropdown-basic"
                >
                  {
                    eventPoints.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onSelectPoints('eventPointValue', item['value'], item['key'])}
                        active={eventPointValue == item['value']}
                      >
                        {item['key']}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
            </TitleContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>{t("Event Limit Users")}:</FieldTitle>
                <div>
                  <CustomCheckbox> {t("Add limit")}
                    <input type="checkbox" checked={addLimitCheckbox} onChange={() => this.changeLimitCheckBox()}/>
                    <CheckMark checked={addLimitCheckbox} />
                  </CustomCheckbox>
                </div>
                <StyledInput
                  type={addLimitCheckbox ? 'number': 'text' }
                  name="limitUser"
                  onChange={this.onChangeInput}
                  value={ addLimitCheckbox ? limitUser : t('All user')}
                  min="1"
                  pattern='[0-9]*'
                  disabled={!addLimitCheckbox}
                />
              </ActivityTypeContainer>
            </TitleContainer>
            <ImageContainer>
              <ActivityTypeContainer>
                <FieldTitle>{t("Frequency")}:</FieldTitle>
                <ActivityDropdown
                  title={recurringEvent}
                  id="dropdown-recurring"
                >
                  {
                    this.recurringTypes.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onChangeTimeZone('recurringEvent', item)}
                        active={recurringEvent === item}
                      >
                        {t(item)}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
              {recurringEvent !== this.recurringTypes[0] && <ActivityTypeContainer>
                <FieldTitle>{`Number Of ${recurringEvent === this.recurringTypes[1] ? t("Weeks") : t('Months')}`}:</FieldTitle>
                <ActivityDropdown
                  title={`${eventLimitValue} ${recurringEvent === this.recurringTypes[1] ? t("Weeks") : t('Months')}`}
                  id="dropdown-limit"
                >
                  {
                    eventLimits.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onChangeTimeZone('eventLimitValue', item)}
                        active={eventLimitValue === item}
                      >
                        {`${item} ${recurringEvent === this.recurringTypes[1] ? t("weeks") : t('Months')}`}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>}
            </ImageContainer>
            <TitleContainer addMargin>
              <FieldTitle>{t("Event Date")}:</FieldTitle>
              <div>
                <CustomCheckbox>{t("All Day")}
                  <input type="checkbox" checked={showDateTimeInput === 'date'} onChange={() => this.changeDateTimeView('date')}/>
                  <CheckMark checked={showDateTimeInput === 'date'} />
                </CustomCheckbox>
                <CustomCheckbox hidden>{t("Repeat Event")}
                  <input type="checkbox" checked={showDateTimeInput === 'repeat'} onChange={() => this.changeDateTimeView('repeat')} />
                  <CheckMark checked={showDateTimeInput === 'repeat'} />
                </CustomCheckbox>
              </div>
              {showDateTimeInput === 'date' ?
                <InputContainer>
                  <div ref={(node) => openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                    <StyledDateTime
                      open={openDateTimePicker === 'date'}
                      inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true}}
                      dateFormat="MM/DD/YYYY"
                      closeOnSelect={true}
                      closeOnTab={true}
                      timeFormat={false}
                      value={date}
                      onChange={(e) => this.changeDate(e, 'date')}
                      isValidDate={valid}
                    />
                    <DateIconContainer onClick={() => this.showDatePicker('date')}>
                      <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                    </DateIconContainer>
                  </div>
                </InputContainer> :
                <div>
                  <StartDateContainer>
                    <InputContainer >
                      <div ref={(node) => openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                        <StyledDateTime
                          open={openDateTimePicker === 'date'}
                          inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true}}
                          dateFormat="MM/DD/YYYY"
                          closeOnSelect={true}
                          closeOnTab={true}
                          timeFormat={false}
                          value={date}
                          onChange={(e) => this.changeDate(e, 'date')}
                          isValidDate={valid}
                        />
                        <DateIconContainer onClick={() => this.showDatePicker('date')}>
                          <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                        </DateIconContainer>
                      </div>
                    </InputContainer>
                    <InputContainer timeInput event={1}>
                      <div ref={(node) => openDateTimePicker === 'time' && this.setDatePickerWrapper(node)}>
                        <StyledDateTime
                          open={openDateTimePicker === 'time'}
                          inputProps={{ placeholder: moment().format('hh:mm a'), readOnly: true}}
                          dateFormat={false}
                          closeOnTab={true}
                          value={time}
                          onChange={(e) => this.changeDate(e, 'time')}
                          timeInput
                        />
                        <i className="far fa-clock" onClick={() => this.showDatePicker('time')} />
                      </div>
                    </InputContainer>
                  </StartDateContainer>
                  <DateJoiner eventPage={1}>to</DateJoiner>
                  <StartDateContainer>
                    {recurringEvent === this.recurringTypes[0] && <InputContainer >
                      <div ref={(node) => openDateTimePicker === 'endDate' && this.setDatePickerWrapper(node)}>
                        <StyledDateTime
                          open={openDateTimePicker === 'endDate'}
                          inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true}}
                          dateFormat="MM/DD/YYYY"
                          closeOnSelect={true}
                          closeOnTab={true}
                          timeFormat={false}
                          value={endDate}
                          onChange={(e) => this.changeDate(e, 'endDate')}
                          isValidDate={valid}
                        />
                        <DateIconContainer onClick={() => this.showDatePicker('endDate')}>
                          <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                        </DateIconContainer>
                      </div>
                    </InputContainer>}
                    <InputContainer timeInput event={1}>
                      <div ref={(node) => openDateTimePicker === 'endTime' && this.setDatePickerWrapper(node)}>
                        <StyledDateTime
                          open={openDateTimePicker === 'endTime'}
                          inputProps={{ placeholder: moment().format('hh:mm a'), readOnly: true}}
                          dateFormat={false}
                          closeOnTab={true}
                          value={endTime}
                          onChange={(e) => this.changeDate(e, 'endTime')}
                          timeInput
                        />
                        <i className="far fa-clock" onClick={() => this.showDatePicker('endTime')} />
                      </div>
                    </InputContainer>
                  </StartDateContainer>
                </div>
              }
              {
                showDateTimeInput === 'repeat' &&
                <CustomDropDown
                  title={selectedMenu}
                  id="dropdown-basic"
                >
                  {
                    this.repeatValues.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onSelect(item)}
                        active={selectedMenu === item}
                      >
                        {t(item)}
                      </CustomMenuItem>
                    ))
                  }
                </CustomDropDown>
              }
            </TitleContainer>
            <TitleContainer>
              <FieldTitle>{t("Email Notifications: Select email option for this Event")}</FieldTitle>
              <LocationContainer>
                {
                  emailOptionKeys.map((item, index) => (
                    <InviteAmigo key={index}>
                      <CustomCheckbox disabled={index === 0 && (recurringEvent !== this.recurringTypes[0])}>
                        <input
                          type="checkbox"
                          checked={emailOption[item] === 1}
                          onChange={() => !(index === 0 && (recurringEvent !== this.recurringTypes[0])) && this.onChangeEmail(item)}
                        />
                        <CheckMark checked={emailOption[item] === 1} disabled={index === 0 && (recurringEvent !== this.recurringTypes[0])}/>
                      </CustomCheckbox>
                      <NameContainer onClick={() => !(index === 0 && (recurringEvent !== this.recurringTypes[0])) && this.onChangeEmail( item)}>
                        <span>{t("Event")}{item == 'launch_event_email' ? t(' Creation') : item == 'event_reminder_email' ? t(' Reminder') : t(' Completion')} </span>
                      </NameContainer>
                      <div className="showInfoText">{showEmailNotificationInfo[index]}</div>
                    </InviteAmigo>
                  ))
                }
              </LocationContainer>
            </TitleContainer>
            <TimezoneContainer>
              <FieldTitle>{t("Event Timezone")}:</FieldTitle>

              <Autocomplete assignedState={'time_zone'} changeHandler={this.onChangeTimeZone} options={timezones.timezones} defaultValue={time_zone}/>
            </TimezoneContainer>
            {
              companiesPermission &&
              <AmigosContainer>
                <TopContainer>
                  <FieldTitle>{t("Invite Companies")}:</FieldTitle>
                  <ButtonContainer>
                    <SaveButton color="#159fc9" type="button" onClick={(e) => this.selectAllCompanies(e)}>
                      <i className="fas fa-check"/>
                      {
                        companies.length === allCompanies.length ? t('deselect all companies') : t('select all companies')
                      }
                    </SaveButton>
                  </ButtonContainer>
                </TopContainer>
                <InviteContainer>
                  <ScrollableContainer>
                    {
                      allCompanies.map((company, index) => (
                        <InviteAmigo key={index}>
                          <CustomCheckbox>
                            <input
                              type="checkbox"
                              checked={companies.includes(company.id)}
                              onChange={() => this.onCheckboxChange(company.id)}
                            />
                            <CheckMark checked={companies.includes(company.id)} />
                          </CustomCheckbox>
                          <NameContainer onClick={() => this.onCheckboxChange(company.id)}>
                            <span>{t(company.company_name)}</span>
                          </NameContainer>
                        </InviteAmigo>
                      ))
                    }
                  </ScrollableContainer>
                </InviteContainer>
              </AmigosContainer>
            }
            { locationPermission && (locationDetails && locationDetails.length > 0 || companyDetails['spouse_or_dependent']) ?
              <AmigosContainer>
                <TopContainer>
                  <FieldTitle>{t("Company Locations")}:</FieldTitle>
                  {locationDetails && locationDetails.length > 0 &&
                    <ButtonContainer>
                      <SaveButton color="#159fc9" onClick={(e) => this.selectAllLocation(e, locationDetails)}>
                        <i className="fas fa-check"/>
                        {
                          city_location.length === locationDetails.length ? t('deselect all locations') : t('select all locations')
                        }
                      </SaveButton>
                    </ButtonContainer>
                  }
                </TopContainer>
                <InviteContainer>
                  <ScrollableContainer>
                    {
                      <LocationContainer minHeight={locationDetails && locationDetails.length}>
                        {
                          locationDetails && locationDetails.length > 0 ? locationDetails.map((loc, index) => (

                            <InviteAmigo key={index}>
                              <CustomCheckbox>
                                <input
                                  type="checkbox"
                                  checked={city_location.includes(loc.id)}
                                  onChange={() => this.onChangeLocation(loc.id)}
                                />
                                <CheckMark checked={city_location.includes(loc.id)} />
                              </CustomCheckbox>
                              <NameContainer onClick={() => this.onChangeLocation(loc.id)}>
                                <span>{t(loc.location)}</span>
                              </NameContainer>
                            </InviteAmigo>

                          )) : null
                        }
                      </LocationContainer>
                    }

                    { companyDetails['spouse_or_dependent'] ?
                      <div style={{display: 'flex'}}>
                        <InviteAmigo>
                          <CustomCheckbox>
                            <input
                              type="checkbox"
                              checked={isSpouse}
                              onChange={() => this.selectSpouseDependent('isSpouse')}
                            />
                            <CheckMark checked={this.state.isSpouse} />
                          </CustomCheckbox>
                          <NameContainer onClick={() => this.selectSpouseDependent( 'isSpouse')}>
                            <span>{t('Spouse')}</span>
                          </NameContainer>
                        </InviteAmigo>
                        <InviteAmigo>
                          <CustomCheckbox>
                            <input
                              type="checkbox"
                              checked={isDependent}
                              onChange={() => this.selectSpouseDependent('isDependent')}
                            />
                            <CheckMark checked={this.state.isDependent} />
                          </CustomCheckbox>
                          <NameContainer onClick={() => this.selectSpouseDependent( 'isDependent')}>
                            <span>{t('Dependent')}</span>
                          </NameContainer>
                        </InviteAmigo>
                        { locationDetails && locationDetails.length === 0 &&
                          <InviteAmigo>
                            <CustomCheckbox>
                              <input
                                type="checkbox"
                                checked={isEmployee}
                                onChange={() => this.selectSpouseDependent('isEmployee')}
                              />
                              <CheckMark checked={this.state.isEmployee} />
                            </CustomCheckbox>
                            <NameContainer onClick={() => this.selectSpouseDependent( 'isEmployee')}>
                              <span>{t('Employee')}</span>
                            </NameContainer>
                          </InviteAmigo>
                        }
                      </div> : null
                    }
                  </ScrollableContainer>
                </InviteContainer>
              </AmigosContainer> : null
            }
            <TitleContainer>
              <FieldTitle>{t("Event Venue")}:</FieldTitle>
              <RadioButtonContainer>
                <RadioButton id="1" handler={this.toggleRadioButton} value={0} isChecked={selectedOption === 0} label={t('At Physical Location')} challengeLeaderBoard={true}/>
                <RadioButton id="2" handler={this.toggleRadioButton} value={1} isChecked={selectedOption === 1} label={t('At Virtual Location')} challengeLeaderBoard={true}/>
              </RadioButtonContainer>
            </TitleContainer>
            {
              selectedOption === 0 ? <TitleContainer>
                <FieldTitle>{t("Event Location")}:</FieldTitle>
                <div>
                  <StyledInput
                    placeholder={t("Enter Location...")}
                    name="location"
                    value={location}
                    onChange={this.onLocationChange}
                    locationInput
                  />
                </div>
                {
                  showPlaces && !!places &&
                              <PlacesContainer>
                                {this.renderPlaces(places)}
                              </PlacesContainer>
                }
              </TitleContainer>
                : <TitleContainer>
                  <FieldTitle>{t("Virtual Event Link")}:</FieldTitle>
                  <div>
                    <StyledInput
                      placeholder={t("Enter Event Link...")}
                      name="eventLink"
                      onChange={this.onChangeInput}
                      value={eventLink}
                    />
                  </div>
                </TitleContainer>
            }
            <TitleContainer>
              <FieldTitle>{t("Event Description")}:</FieldTitle>
              <EditorContainer>
                <Editor content={t(description)} onChange={this.onChangeDescription} />
              </EditorContainer>
            </TitleContainer>
            <ImageContainer>
              {<SaveButton type="submit" disabled={isDisabled||buttonStatus} addMargin>{t("create event")}</SaveButton>}
            </ImageContainer>
          </FormContainer>
          <FeaturedEventsContainer>
            <FeaturedEvents history={history} />
          </FeaturedEventsContainer>
        </EventContainer>
      </Layout>
    )
  }
}

CreateEvent.propTypes = {
  createEvent: PropTypes.func.isRequired,
  getPlacesAction: PropTypes.func.isRequired,
  places: PropTypes.array,
  isLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  fetchAllCompanies: PropTypes.func.isRequired,
  allCompanies: PropTypes.array,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  locationDetails: PropTypes.array,
  allowChallengeEventForSpouseDependent: PropTypes.func,
  companyDetails:PropTypes.object,
  userPermissions: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  allowChallengeEventForSpouseDependent: () => dispatch(allowChallengeEventForSpouseDependent)
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateEvent));
