import { CircularBar } from "./style";
import React, { Component } from "react";
import PropTypes from "prop-types";

class CircularProgressBar extends Component {
  render() {
    const sqSize = this.props.sqSize;
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - (dashArray * this.props.percentage) / 100;

    return (
      <CircularBar stroke={this.props.color}>
        <svg
          width={this.props.sqSize}
          height={this.props.sqSize}
          viewBox={viewBox}
        >
          <circle
            className="circle-background"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
          />
          <circle
            className="circle-progress"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
            transform={`rotate(-90 ${this.props.sqSize / 2} ${
              this.props.sqSize / 2
            })`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
            }}
          />
          {this.props.percentage >= 100 ? (
            <React.Fragment>
              {" "}
              <svg
                x="20%"
                y={"22%"}
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
              >
                <path
                  d="M28.602 7.925 13.969 23.701l-6.016-6.486c-.544-.586-1.337-.814-2.08-.6-.743.216-1.323.841-1.522 1.642s.013 1.656.556 2.242l7.539 8.129c.404.435.952.68 1.523.68a2.08 2.08 0 0 0 1.524-.68L31.648 11.21a2.45 2.45 0 0 0 .556-2.242c-.2-.801-.78-1.427-1.523-1.641-.742-.216-1.535.013-2.08.599"
                  fill={"#76AB78"}
                />
              </svg>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <text
                className="circle-text"
                x="50%"
                y={this.props.hideText ? "50%" : "45%"}
                dy=".3em"
                textAnchor="middle"
              >
                {`${this.props.percentage}%`}
              </text>
            </React.Fragment>
          )}
          <text x="50%" y="60%" textAnchor="middle" className="points-earned">
            {this.props.textValue ? this.props.textValue : ""}
          </text>
        </svg>
      </CircularBar>
    );
  }
}

CircularProgressBar.defaultProps = {
  sqSize: 100,
  percentage: 25,
  strokeWidth: 5,
};

CircularProgressBar.propTypes = {
  sqSize: PropTypes.number,
  percentage: PropTypes.number,
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
  hideText: PropTypes.bool,
  textValue: PropTypes.string,
};

export default CircularProgressBar;
